import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Renderer2,
  HostListener,
} from "@angular/core";
import { BookContentListService } from "../book-content-list/book-content-list.service";
import { NotifierService } from "angular-notifier";
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import * as domtoimage from "dom-to-image";
import * as html2pdf from "html2pdf.js";
import { AllApisService } from "src/app/all-apis.service";
declare var $: any;

@Component({
  selector: "app-chatbot",
  templateUrl: "./chatbot.component.html",
  styleUrls: ["./chatbot.component.css"],
})
export class ChatbotComponent implements OnInit {
  // chatbox = document.querySelector(".chatbox");
  // chatInput = document.querySelector(".chat-input textarea");
  // sendChatBtn = document.querySelector(".chat-input span");
  @ViewChild("chatInput") chatInput: ElementRef;
  @ViewChild("chatbox") chatbox: ElementRef;
  @ViewChild("chatbot") chatbot: ElementRef;

  userMessage: string = null; // Variable to store user's message
  inputInitHeight: number;
  series: number;
  smode: number;
  uid: number;
  name: string;
  grade: string;
  chatper: string;
  startTime: any;
  showChatbot: boolean = false;
  isSeries = true;
  allSeries = [];
  studentDetail: any;
  colors = ["#FCF5EE", "#EFFEF5", "#E1F4FC", "#F2F6F9", "#F8F7E7"]; // List of colors you want to use
  selectedSeries = "";
  avatarNumber = "1";
  newStudent = false;
  showModal = false;
  username = "";

  constructor(
    private serv: BookContentListService,
    private renderer: Renderer2,
    private notifier: NotifierService,
    public apiServ: AllApisService
  ) {}

  ngAfterViewInit() {
    // Initialize inputInitHeight after view is initialized
    // this.inputInitHeight = this.chatInput.nativeElement.scrollHeight;
    this.adjustChatHeight();
    console.log(this.chatInput.nativeElement);
    console.log(this.chatbox.nativeElement);
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.adjustChatHeight();
  }

  adjustChatHeight() {
    const viewportHeight = window.innerHeight;
    const headerHeight = document.querySelector("header").clientHeight; // Adjust selector as per your header element
    const inputBarHeight = this.chatInput.nativeElement.clientHeight;
    const availableHeight = viewportHeight - headerHeight - inputBarHeight;
    this.chatbox.nativeElement.style.height = availableHeight + "px";
  }

  downloadChatAsPDF(): void {
    const chatboxElement = this.chatbox.nativeElement;

    let date = new Date().toLocaleString();

    let title = `StudentMitra_history_${date}`;

    const chatboxClone = chatboxElement.cloneNode(true);

    html2pdf().from(chatboxClone).save(title);
  }

  downloadChatAsText(): void {
    const chatboxElement = document.querySelector(".chatbox");
    const chatMessages = chatboxElement.querySelectorAll(".chat");
    let chatText = "";

    const username =
      this.studentDetail.firstName + " " + this.studentDetail.lastName;
    chatText += "====================\n";
    chatText += "   StudentMitra\n";
    chatText += "====================\n\n";

    chatMessages.forEach((message: HTMLElement) => {
      const isIncoming = message.classList.contains("incoming");
      const messageTextElement = message.querySelector("p");
      const timestampElement = message.querySelector("span.timestamp");

      // Check if elements are not null before accessing textContent
      if (messageTextElement && timestampElement) {
        const messageText = messageTextElement.textContent.trim();
        const messageType = isIncoming ? "StudentMitra: " : `${username}: `;
        const timestamp = timestampElement.textContent.trim();
        chatText += `${timestamp}: ${messageType} ${messageText}\n`;
      }
    });

    console.log("chattext", chatText);

    const now = new Date();
    const formattedDate = now.toISOString().replace(/[-:]/g, "").slice(0, -5); // Remove dashes and colons from ISO string
    const filename = `StudentMitra_history_${formattedDate}.txt`;
    let that = this;
    this.serv
      .getHistoryFile({ fileData: chatText, fileName: filename })
      .subscribe((res) => {
        console.log("file url", res);
        if (res.status) {
          window.location.href =
            this.apiServ.baseurl + "/txtDownload?filename=" + filename;
        }
      });

    // const downloadLink = document.createElement("a");
    // downloadLink.href = dataUri;
    // downloadLink.download = filename;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  }

  onInputChange() {
    this.chatInput.nativeElement.style.height = `${this.inputInitHeight}px`;
    this.chatInput.nativeElement.style.height = `${this.chatInput.nativeElement.scrollHeight}px`;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey && window.innerWidth > 800) {
      event.preventDefault();
      this.handleChat();
    }
  }

  ngOnInit(): void {
    // let stringSeries = JSON.parse(localStorage.getItem("series"));
    // this.series = Number(stringSeries);
    // console.log(typeof this.series, typeof stringSeries);
    let s = JSON.parse(localStorage.getItem("smode"));
    this.smode = Number(s);
    let ui = JSON.parse(localStorage.getItem("uid"));
    this.uid = Number(ui);
    this.name = JSON.parse(localStorage.getItem("name"));
    this.grade = JSON.parse(localStorage.getItem("grade"));
    this.studentDetail = JSON.parse(localStorage.getItem("studentDetail"));
    console.log("this.studentDetail", this.studentDetail);
    this.serv
      .getSeries({ std: this.grade, schoolId: this.studentDetail.smSchoolId })
      .subscribe((res) => {
        console.log("school series name", res);
        if (res.series && res.series.length) {
          this.isSeries = true;
          this.allSeries = res.series;
          this.allSeries = this.allSeries.filter((obj) => obj.code !== null);
          this.allSeries.forEach((card) => {
            card.backgroundColor =
              this.colors[Math.floor(Math.random() * this.colors.length)];
          });
        } else {
          this.notifier.notify("error", "No series enrolled for this student.");
          this.isSeries = false;
        }
      });

    //sourceId
    this.serv
      .getAvatar({
        smSchoolId: this.studentDetail.smSchoolId,
        userId: this.studentDetail.sourceId,
      })
      .subscribe((res) => {
        console.log("avatarr data", res);
        if (res.status) {
          this.avatarNumber = res.data.avatarNumber;
          this.newStudent = false;
          this.showModal = true;
        } else {
          // this.notifier.notify("error", "No series enrolled for this student.");
          this.newStudent = true;
          $("#uploadVideoModel").modal("show");
          // document
          //   .getElementById("uploadVideoModel")
          //   .addEventListener("hidden.bs.modal", function () {
          //     console.log("run");
          //     document.getElementById("videoModal").style.zIndex = "1051";
          //   });
        }
      });

    this.username =
      this.studentDetail.firstName + " " + this.studentDetail.lastName;
  }

  doneUploadVideo() {
    $("#uploadVideoModel").modal("hide");
    // document.getElementById("videoModal").style.zIndex = "1051";
  }

  selectPicture(avatarNumber) {
    console.log("log aaya", avatarNumber);
    this.doneUploadVideo();
    this.avatarNumber = avatarNumber;
    this.serv
      .saveAvatar({
        smSchoolId: this.studentDetail.smSchoolId,
        userId: this.studentDetail.sourceId,
        avatarNumber: avatarNumber,
      })
      .subscribe((res) => {
        console.log("avatarr data saved", res);
        if (res.status) {
          this.notifier.notify("info", "Avatar saved.");
        }
      });
  }

  openChatBot(id, title) {
    if (id == null) {
      this.notifier.notify("error", "This series has code as null.");
    } else {
      this.series = Number(id);
      this.selectedSeries = title.toUpperCase();
      this.renderer.addClass(document.body, "fullscreen");
      let currentTime = new Date();
      console.log("currentTime", currentTime);
      localStorage.setItem("startTime", JSON.stringify(currentTime));
      localStorage.setItem("selectedSeries", JSON.stringify(title));
      this.showChatbot = true;
    }
  }

  getSubjectIcon(code) {
    let condition = Number(code);
    if (code == 1) {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English-%20icons.svg";
    } else if (code == 2) {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English-%20icons.svg";
    } else if (code == 3) {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English-%20icons.svg";
    } else if (code == 4) {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English%20Grammar-%20icons.svg";
    } else if (code == 5) {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English%20Grammar-%20icons.svg";
    } else {
      return "https://storage.googleapis.com/bkt-sm-prod-testgenerator/lms-ch/English%20Grammar-%20icons.svg";
    }
  }

  closeChatbot() {
    // this.clearChat();
    this.renderer.removeClass(document.body, "fullscreen");
    this.showChatbot = false;
    this.calculateTime();
  }

  calculateTime() {
    if (
      localStorage.getItem("startTime") &&
      localStorage.getItem("selectedSeries")
    ) {
      this.startTime = new Date(JSON.parse(localStorage.getItem("startTime")));
      this.chatper = JSON.parse(localStorage.getItem("selectedSeries"));
      let endTime = new Date();
      const timeDifferenceInMilliseconds =
        endTime.getTime() - this.startTime.getTime();
      let timeDifferenceInSec = timeDifferenceInMilliseconds / 1000;
      console.log("time in minture", timeDifferenceInSec);
      let useageReport = {};
      useageReport["smSchoolId"] = this.studentDetail.smSchoolId;
      useageReport["sourceId"] = this.studentDetail.sourceId;
      useageReport["name"] =
        this.studentDetail.firstName + " " + this.studentDetail.lastName;
      useageReport["timeUsed"] = timeDifferenceInSec;
      useageReport["title"] = this.chatper;
      useageReport["lastSeenDate"] = new Date();
      useageReport["schoolName"] = this.studentDetail["student.schoolName"];
      console.log("this.useageReport", useageReport);
      this.serv.updateUseageReport(useageReport).subscribe((res) => {
        console.log("updated usage report", res);
      });
    }
  }

  clearChat() {
    const chatboxElement = this.chatbox.nativeElement;
    while (chatboxElement.firstChild) {
      chatboxElement.removeChild(chatboxElement.firstChild);
    }
  }

  handleChat() {
    let domElement = document.querySelector("textarea");
    domElement.style.height = "55px";
    console.log("enter aaya");
    this.userMessage = this.chatInput.nativeElement.value.trim(); // Get user entered message and remove extra whitespace
    if (!this.userMessage) return;
    this.chatInput.nativeElement.value = "";
    this.chatInput.nativeElement.style.height = `${this.inputInitHeight}px`;
    this.userMessage += `,\n Additional Params : \nSeries=${this.series} \nS_mode=${this.smode} \nUid=${this.uid} \nGrade=${this.grade} \nName=${this.name}`;
    this.chatbox.nativeElement.appendChild(
      this.createChatLi(this.userMessage, "outgoing")
    );
    this.chatbox.nativeElement.scrollTo(
      0,
      this.chatbox.nativeElement.scrollHeight
    );

    setTimeout(() => {
      const incomingChatLi = this.createChatLi("", "incoming");
      const thinkingGif = this.renderer.createElement("img");
      this.renderer.setAttribute(
        thinkingGif,
        "src",
        "../../assets/chatbot/StudentMitra.gif"
      );
      this.renderer.setStyle(thinkingGif, "width", "60px");
      this.renderer.appendChild(incomingChatLi, thinkingGif);
      this.renderer.appendChild(this.chatbox.nativeElement, incomingChatLi);
      this.chatbox.nativeElement.scrollTo(
        0,
        this.chatbox.nativeElement.scrollHeight
      );

      this.generateResponse(incomingChatLi).then(() => {
        this.renderer.removeChild(incomingChatLi, thinkingGif); // Remove the GIF once response is received
      });
    }, 100);
  }
  createChatLi(message: string, className: string): HTMLElement {
    // Inside createChatLi function
    const chatLi = this.renderer.createElement("li");
    this.renderer.addClass(chatLi, "chat");
    this.renderer.addClass(chatLi, className);

    const chatContentContainer = this.renderer.createElement("div");
    this.renderer.appendChild(chatLi, chatContentContainer);

    const flexContainer = this.renderer.createElement("div");
    this.renderer.setStyle(flexContainer, "display", "flex");
    this.renderer.appendChild(chatContentContainer, flexContainer);

    const messageContainer = this.renderer.createElement("div");
    this.renderer.appendChild(flexContainer, messageContainer);

    const pElement = this.renderer.createElement("p");
    this.renderer.appendChild(messageContainer, pElement);
    this.renderer.setProperty(pElement, "textContent", message);
    this.renderer.setStyle(pElement, "color", "#fff");
    this.renderer.setStyle(pElement, "padding", "12px 16px");
    this.renderer.setStyle(
      pElement,
      "border-radius",
      className === "incoming" ? "10px 10px 0 10px;" : "10px 10px 0px 10px"
    );
    this.renderer.setStyle(pElement, "max-width", "100%");
    this.renderer.setStyle(pElement, "font-size", "0.80rem");
    this.renderer.setStyle(
      pElement,
      "background",
      className === "incoming" ? "#F7912A" : "#004B85"
    );

    const spanElement = this.renderer.createElement("span");
    this.renderer.addClass(spanElement, "material-symbols-outlined");

    const imgElement = this.renderer.createElement("img");
    let avatarSrc =
      className === "incoming"
        ? "../../assets/chatbot/chatbotavatar.svg"
        : `../../assets/chatbot/avatar${this.avatarNumber}.svg`;
    this.renderer.setAttribute(imgElement, "src", avatarSrc);
    imgElement.style.width = className === "incoming" ? "32px" : "30px";
    this.renderer.appendChild(spanElement, imgElement);
    this.renderer.setStyle(spanElement, "display", "inline-block");

    if (className === "incoming") {
      this.renderer.appendChild(flexContainer, spanElement);
      this.renderer.appendChild(flexContainer, messageContainer);
    } else {
      this.renderer.appendChild(flexContainer, messageContainer);
      this.renderer.appendChild(flexContainer, spanElement);
    }

    const timestampSpan = this.renderer.createElement("span");
    let time = this.getCurrentTime();
    let messageType =
      className === "incoming" ? "StudentMitra " : `${this.username} `;
    this.renderer.setProperty(timestampSpan, "textContent", `${time}`);
    this.renderer.addClass(timestampSpan, "timestamp");
    this.renderer.setStyle(timestampSpan, "font-size", "0.7rem");
    this.renderer.setStyle(timestampSpan, "opacity", "0.6");
    this.renderer.setStyle(timestampSpan, "color", "#004B85"); // Set color to blue

    // Adjust alignment based on the message direction
    if (className === "incoming") {
      this.renderer.setStyle(spanElement, "display", "none");
      this.renderer.setStyle(pElement, "display", "none");
      this.renderer.setStyle(timestampSpan, "display", "none");
    } else {
      this.renderer.setStyle(spanElement, "float", "right");
      this.renderer.setStyle(timestampSpan, "float", "right");
      // this.renderer.setStyle(pElement, "float", "right");
    }

    this.renderer.appendChild(messageContainer, timestampSpan);

    return chatLi;
  }
  generateResponse(chatElement: HTMLElement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const messageElement = chatElement.querySelector("p");
      const avatarElement = chatElement.querySelector("span");
      const timeElement = chatElement.querySelector(".timestamp");
      var classMap = new Map();
      classMap.set("I", "1");
      classMap.set("II", "2");
      classMap.set("III", "3");
      classMap.set("IV", "4");
      classMap.set("V", "5");
      classMap.set("VI", "6");
      classMap.set("VII", "7");
      classMap.set("VIII", "8");
      if (classMap.has(this.grade)) {
        this.grade = classMap.get(this.grade);
      }
      let obj = {
        series: this.series,
        query: this.userMessage,
        s_mode: this.smode,
        uid: this.uid,
        grade: this.grade,
        name: this.name,
      };
      // messageElement.innerHTML =
      //   '<img src="../../assets/chatbot/StudentMitra.gif" style="width: 60px;">';
      this.serv.getAnswer(obj).subscribe(
        (res) => {
          console.log("data res", res);
          messageElement.style.display = "block";
          if (timeElement instanceof HTMLElement) {
            // Now you can access the style property safely
            timeElement.style.display = "inline-block";
          }
          avatarElement.style.display = "inline-block";
          let text = "";
          text =
            text +
            "Chat_Bot_Answer:{\n question_type: " +
            res.data.chat_bot_answer.question_type +
            ",\n can_answer: " +
            res.data.chat_bot_answer.can_answer +
            ",\n reason: " +
            res.data.chat_bot_answer.reason +
            ",\n answer: " +
            res.data.chat_bot_answer.answer +
            "\n}, \n context: " +
            res.data.context +
            ", \n source_documents: \n";
          for (let i = 0; i < res.data.source_documents.length; i++) {
            text =
              text +
              "{ source: " +
              res.data.source_documents[i].source +
              ", page: " +
              res.data.source_documents[i].page +
              " },\n ";
          }
          messageElement.textContent = text;
          this.chatbox.nativeElement.scrollTo(
            0,
            this.chatbox.nativeElement.scrollHeight
          );
          resolve(); // Resolve the Promise once response is received
        },
        (error) => {
          messageElement.style.display = "block";
          if (timeElement instanceof HTMLElement) {
            // Now you can access the style property safely
            timeElement.style.display = "inline-block";
          }
          avatarElement.style.display = "inline-block";
          messageElement.classList.add("error");
          messageElement.textContent =
            "Oops! Something went wrong. Please try again.";
          this.chatbox.nativeElement.scrollTo(
            0,
            this.chatbox.nativeElement.scrollHeight
          );
          reject(error); // Reject the Promise if there's an error
        }
      );
    });
  }

  getCurrentTime(): string {
    const now = new Date();
    return `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  // This function will be called when the tab is closed or back button is pressed
  @HostListener("window:beforeunload", ["$event"])
  onBeforeUnload(event: any): void {
    // Call your function here
    this.myFunctionToExecuteOnUnload();
  }

  myFunctionToExecuteOnUnload() {
    // Implement your logic here
    console.log("Tab is closing or back button is pressed.");
    // You can perform additional actions as needed.
    this.calculateTime();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event: any): void {
    // Handle the back button press here
    console.log("Back button pressed");
    // You can perform any specific action you need when the back button is pressed.
    this.calculateTime();
  }
}
